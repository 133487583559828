.body {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.controlBar {
  align-self: stretch;
  margin-top: 32px;
}

.exploreGrid {
  margin-top: 40px;
  width: 100%;
}

.subheader {
  margin-top: 12px;
}