.descriptionAndTimestamp {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.left {
  column-gap: 16px;
  display: flex;
}


.nftPhoto {
  border-radius: 8px;
  height: 48px;
  width: 48px;
}

.profileLink {
  align-items: center;
  column-gap: 8px;
  display: flex;
}

.profilePhoto {
  align-items: center;
  background-color: var(--color-lightPurple);
  border-radius: 50%;
  display: flex;
  height: 48px;
  justify-content: center;
  width: 48px;
}

.transaction {
  align-items: center;
  border-radius: 16px;
  box-shadow: var(--box-shadow-button);
  column-gap: 16px;
  display: flex;
  justify-content: space-between;
  padding: 24px;
  width: 600px;
}
