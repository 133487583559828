.body {
  margin-top: 32px;
  width: 600px;
}

.priceInput {
  padding-left: var(--padding-left-solSymbol);
}

.submitButton {
  display: block;
  margin: auto;
  margin-top: 48px;
  width: 260px;
}