.button {
  align-items: center;
  border-radius: 100px;
  box-shadow: var(--box-shadow-button);
  column-gap: 8px;
  display: flex;
  padding: 12px 24px;
}

.button:hover {
  cursor: pointer;
}

.popoverContent {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  width: 200px;
}