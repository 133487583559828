.bidButton {
  width: 168px;
}

.bidSuccess {
  max-width: 800px;
}

.bidSuccessImage {
  margin: auto;
  margin-top: 48px;
  width: 586px;
}

.body {
  column-gap: 48px;
  display: flex;
  margin-top: 48px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 64px;
}

.description {
  margin-top: 8px;
}

.noWithdraw {
  margin-top: 16px;
}

.priceInput {
  margin-top: 16px;
}

.right {
  width: 416px;
}

.walletBalance {
  margin-top: 48px;
}