.actionButton {
  margin-top: 20px;
}

.activity {
  margin-top: 48px;
}

.creator {
  margin-top: 32px;
}

.creatorAndOwner {
  display: flex;
  flex-direction: column;
}

.description {
  margin-top: 48px;
}

.nameAndButton {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.price {
  margin-top: 48px;
}