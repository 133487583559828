.container {
  align-items: center;
  border-radius: 24px;
  box-shadow: var(--box-shadow-button);
  display: flex;
  flex-direction: column;
  padding: 48px 64px;
}

.container:hover {
  cursor: pointer;
}

.description {
  margin-top: 10px;
}

.fileTypes {
  margin-top: 12px;
}

.image {
  background-color: var(--color-lightPurple);
  border-radius: 16px;
  height: 300px;
  width: 300px;
}

.title {
  margin-top: 24px;
}