/* Keep in sync with BackgroundOverlay.module.css */
@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.closeRow {
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
  position: absolute;
  top: -36px;
  left: 0px;
}

.container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.footer {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.modal {
  background-color: var(--color-white);
  border-radius: 12px;
  box-sizing: border-box;
  margin: auto;
  max-height: 90vh;
  max-width: 100%;
  overflow: hidden;
  padding: 64px 56px;
}

.outsideClick {
  border-radius: 16px;
  display: flex;
  justify-content: center;
  max-height: 95%;
  max-width: 90%;
  overflow: visible;
  position: relative;
  z-index: 3;

  /* opacity: 0; */
  /* animation-name: fadein; */
  /* animation-duration: 0.1s; */
  /* animation-fill-mode: forwards; */
}

.title {
  column-gap: 60px;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  padding: 0px 40px;
}

@media only screen and (max-width: 640px) {
  .footer {
    margin-bottom: 30px;
  }

  .modal {
    padding: 30px 0px 0px;
  }

  .titleAndClose {
    column-gap: 20px;
    padding: 0px 16px;
  }
}
