.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  margin-top: 48px;
  row-gap: 64px;
}

.progressBar {
  margin: auto;
}

.progressBarContainer {
  align-items: center;
  align-self: stretch;
  display: flex;
  justify-content: space-between;
}