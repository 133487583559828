.textGradient {
  background: var(--background-purpleGradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.textWhite {
  color: white;
}

.toggle {
  align-items: center;
  background-color: white;
  border-radius: 100px;
  box-shadow: var(--box-shadow-button);
  box-sizing: border-box;
  display: grid;
  height: 70px;
  justify-content: space-around;
  position: relative;
  width: 100%;
}

.toggleButton {
  z-index: 1;
}

.toggleHighlight {
  background: var(--background-purpleGradient);
  border-radius: 100px;
  height: 54px;
  position: absolute;
  top: 8px;
  transition: left 0.5s;
}
