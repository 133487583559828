.container {
  align-items: flex-start;
  column-gap: 64px;
  display: flex;
  justify-content: center;
}

.description {
  margin-top: 12px;
}

.gradientText {
  background: var(--background-purpleGradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.mintingWith {
  align-items: center;
  column-gap: 24px;
  display: flex;
  margin-top: 32px;
}

.mintButton {
  align-self: flex-end;
  margin-top: 42px;
  width: 280px;
}

.preview {
  flex-shrink: 0;
}

.right {
  display: flex;
  flex-direction: column;
}