/* TODO: hover */
.button {
  align-items: center;
  background-color: var(--color-white);
  border-radius: 50%;
  box-shadow: var(--box-shadow-button);
  display: flex;
  height: 48px;
  justify-content: center;
  width: 48px;
}