.container {
  background-color: var(--color-white);
  border-radius: 24px;
  box-shadow: var(--box-shadow-button);
  box-sizing: border-box;
  max-width: 348px;
  padding-top: 12px;
  overflow: hidden;
  width: 100%;
}

.image {
  border-radius: 16px;
  box-sizing: border-box;
  height: 348px;
  max-width: 348px;
  padding: 0px 12px;
  width: 100%;
}

.info {
  margin-top: 32px;
  padding: 0px 24px;
}

.labelAndValue {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.priceAndDate {
  column-gap: 32px;
  display: flex;
  margin-top: 20px;
}

.separator {
  background-color: var(--color-tertiary);
  height: 1px;
  margin-top: 20px;
  width: 100%;
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media only screen and (max-width: 640px) {
  .image {
    height: 281px;
    width: 281px;
  }
}