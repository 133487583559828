.description {
  margin-top: 24px;
}

.image {
  margin-left: 49px;
  width: 187px;
}

.info {
  margin: auto;
  max-width: 968px;
  padding-bottom: 48px;
  padding-top: 104px;
}

@media only screen and (max-width: 1024px) {
  .info {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 768px) {
  .info {
    padding-top: 64px;
  }
}