@keyframes shimmer {
  from {
    background-color: var(--color-lightPurple);
  }

  to {
    background-color: var(--color-white);
  }
}


.body {
  column-gap: 64px;
  display: flex;
}

.couldNotFindDescription {
  margin-top: 24px;
}

.image {
  background-color: var(--color-lightPurple);
  filter: var(--drop-shadow-button);
  min-height: 543px;
  width: 543px;

  animation-duration: 3s;
  animation-name: shimmer;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.info {
  flex-grow: 1;
}

.left {
  align-self: flex-start;
}

.links {
  margin-top: 60px;
}

.tagsContainer {
  margin-top: 48px;
}