.backButton {
  margin-top: 32px;
}

.firstTimeButton {
  margin: auto;
  margin-top: 40px;
}

.icon {
  height: 40px;
  width: 40px;
}

.loadingDescription {
  margin-top: 20px;
  max-width: 448px;
}

.loadingSpinner {
  margin: auto;
  margin-top: 32px;
}

.needInstall {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.ol {
  margin-top: 12px;
  text-align: left;
}

.pleaseConnect {
  max-width: 453px;
}

.wallet {
  align-items: center;
  background-color: var(--color-primary) !important;
  border-radius: 12px;
  column-gap: 20px;
  display: flex;
  justify-content: center;
  padding: 16px 32px;
  width: 314px;
}

.wallets {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  row-gap: 24px;
}

/* TODO: hover states */

/* Wallet styles */
.walletPhantom {
  background-color: var(--color-phantomPurple) !important;
}