body {
  background-color: white;
  height: 100%;
  margin: 0px;
  overflow-y: scroll;
}

html {
  height: 100%;
}

#root {
  height: 100%;
}

/* TODO: IDK what's inserting this random iframe... */
body > iframe {
  display: none;
}

.buttonPlain {
  border-style: none;
  outline: none;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}

.buttonPlain:hover {
  cursor: pointer;
}

/* See https://stackoverflow.com/questions/1833734/display-inline-block-extra-margin/8262649#8262649 */
.hideText {
  color: transparent;
  display: block;
  font-size: 0;
  line-height: 0;
}

/* TODO: add responsive versions */
:root {
  --padding-left-https: 63px;
  --padding-left-instagram: 201px;
  --padding-left-twitter: 171px;
  --padding-left-username: 177px;

  --padding-left-solSymbol: 45px;
}