.button {
  align-items: center;
  background-color: var(--color-white) !important;
  border-radius: 24px;
  box-shadow: var(--box-shadow-button);
  column-gap: 8px;
  display: inline-flex;
  padding: 8px 16px 8px 8px;
}

.image {
  align-items: center;
  background-color: var(--color-lightPurple);
  border-radius: 50%;
  display: flex;
  height: 32px;
  justify-content: center;
  width: 32px;
}