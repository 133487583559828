.container {
  column-gap: 12px;
  display: flex;
  height: 24px;
}

.input {
  background-color: transparent;
  border-style: none;
  outline: none;
}

.secondary {
  color: var(--color-primary);
}

.secondary::placeholder {
  color: var(--color-secondary);
}

.white {
  color: var(--color-white);
}

.white::placeholder {
  color: var(--color-white);
}
