.container {
  align-items: center;
  column-gap: 60px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 54px;
  padding-top: 54px;
}

.image {
  border-radius: 32px;
  filter: var(--drop-shadow-button);
  height: 600px;
  width: 600px;
}

.left {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  row-gap: 24px;
}

@media only screen and (max-width: 1500px) {
  .image {
    height: 500px;
    width: 500px;
  }
}

@media only screen and (max-width: 1300px) {
  .image {
    height: 400px;
    width: 400px;
  }
}

@media only screen and (max-width: 1024px) {
  .image {
    height: 350px;
    width: 350px;
  }
}

@media only screen and (max-width: 868px) {
  .container {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-bottom: 54px;
    padding-top: 40px;
    text-align: center;
  }

  .image {
    height: auto;
    margin-top: 36px;
    max-width: 500px;
    width: 100%;
  }

  .left {
    align-items: center
  }
}