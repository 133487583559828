.container {
  position: relative;  
}

.pill {
  bottom: 0px;
  left: 24px;
  position: absolute;
  transform: translateY(50%);
  z-index: 1;
}