.container {
  align-items: flex-start;
  border-bottom: 1px solid var(--color-tertiary);
  display: flex;
  justify-content: space-between;
}

.left {
  align-items: flex-end;
  column-gap: 24px;
  display: flex;
}