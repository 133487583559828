.container {
  align-items: flex-start;
  column-gap: 64px;
  display: flex;
  justify-content: center;
}

.description {
  margin-top: 12px;
}

.inputs {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  row-gap: 32px;
}

.inputsAndTitle {
  display: flex;
  flex-direction: column;
  max-width: 640px;
}

.nextButton {
  align-self: flex-end;
  margin-top: 42px;
}

.preview {
  flex-shrink: 0;
}