.active {
  align-items: center;
  background: var(--background-purpleGradient);
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
}

.inactive {
  background: white;
  border: 1px solid var(--color-secondary);
  border-radius: 4px;
  box-sizing: border-box;
  height: 24px;
  width: 24px;
}