.anotherTimeButton {
  margin-top: 20px;
}

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.description {
  margin-top: 48px;
  max-width: 700px;
}

.listButton {
  margin-top: 36px;
}

.preview {
  margin-top: 36px;
}