.image {
  background-color: var(--color-lightPurple);
  border-radius: 16px;
  height: 324px;
  max-width: 100%;
  object-fit: cover;
  width: 324px;
}

@media only screen and (max-width: 640px) {
  .image {
    height: 281px;
    width: 281px;
  }
}

