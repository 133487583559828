.image {
  border-radius: 16px;
  height: 324px;
  width: 324px;
}

@media only screen and (max-width: 640px) {
  .image {
    height: 281px;
    width: 281px;
  }
}