.form {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 640px;
  row-gap: 52px;
}

.photoInput {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.saveButton {
  align-self: center;
  width: 173px;
}

.socialLinks {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  row-gap: 24px;
}

.instagramInput {
  padding-left: var(--padding-left-instagram);
}

.twitterInput {
  padding-left: var(--padding-left-twitter);
}

.usernameInput {
  padding-left: var(--padding-left-username);
}

.websiteInput {
  padding-left: var(--padding-left-https);
}