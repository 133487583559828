.buttons {
  column-gap: 36px;
  display: flex;
  margin-bottom: 16px;
}

.container {
  border-bottom: 1px solid var(--color-tertiary);
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.tabs {
  align-self: flex-end;
  column-gap: 58px;
  display: flex;
}