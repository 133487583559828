.body {
  margin-top: 32px;
  width: 630px;
}

.saveButton {
  display: block;
  margin: auto;
  margin-top: 48px;
  width: 260px;
}