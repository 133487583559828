.container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: space-between;
}

.containerInner {
  align-self: stretch;
  flex-grow: 1;
  padding-bottom: 100px;
}

.nfts {
  margin-top: 42px;
}

.profilePhoto {
  background-color: var(--color-tertiary);
  border: 8px solid var(--color-white);
  border-radius: 50%;
  box-sizing: border-box;

  height: 153px;
  width: 153px;
}

.profilePhotoContainer {
  margin-top: -76.5px;
  position: relative;
}

.row1 {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.row2 {
  margin-top: 16px;
}

.row1Right {
  align-items: center;
  column-gap: 28px;
  display: flex;
}

.shadowButtons {
  align-items: center;
  column-gap: 16px;
  display: flex;
}

.shadowButtonSocial {
  align-self: center;
  height: 36px;
  padding: 8px;
  width: 36px;
}