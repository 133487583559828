.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 60px;
  padding-top: 60px;
}

.containerOuter {
  background: var(--background-purpleGradient);
  position: relative;
}

.logo {
  color: var(--color-white);
  font-family: "TanNimbus", serif;
  font-size: 32px;
}

.navButtons {
  column-gap: 64px;
  display: flex;
  justify-content: space-around;
  margin-top: 36px;
}

.privacyAndTerms {
  margin-top: 40px;
}

@media only screen and (max-width: 1024px) {
  .logo {
    width: 300px;
  }
}

@media only screen and (max-width: 900px) {
  .navButtons {
    align-items: center;
    flex-direction: column;
    margin-top: 24px;
    row-gap: 16px;
  }
}
