.ant-popover-arrow {
  display: none;
}

.ant-popover-inner {
  border-radius: 8px;
  box-shadow: var(--box-shadow-button);
}

.ant-popover-inner-content {
  padding: 24px;
}