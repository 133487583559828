.container {
  background-color: var(--color-shader);
  border-radius: 16px;
  padding: 16px 24px 24px 24px;
}

.loadingSpinner {
  display: inline-block;
}

.row1 {
  align-items: center;
  column-gap: 24px;
  display: flex;
}

.row2 {
  margin-top: 8px;
}