.availability {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.button {
  align-items: center;
  border-radius: 100px;
  box-shadow: var(--box-shadow-button);
  column-gap: 8px;
  display: flex;
  padding: 12px 24px;
}

.button:hover {
  cursor: pointer;
}

.market {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.popoverContent {
  display: flex;
  flex-direction: column;
  row-gap: 36px;
  width: 360px;
}

.popoverContentSectionContent {
  margin-top: 26px;
}

.priceInputs {
  column-gap: 16px;
  display: flex;
}

.setPriceButton {
  border-radius: 16px;
  margin-top: 16px;
  width: 100%;
}