.body {
  max-width: 730px;
}

.inputs {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 48px;
  max-width: 640px;
  row-gap: 48px;
}

.listButton {
  display: block;
  margin: auto;
  margin-top: 48px;
  width: 260px;
}

.priceInput {
  padding-left: var(--padding-left-solSymbol);
}