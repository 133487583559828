.ldsDualRing {
  display: block;
  margin: auto;
  width: 64px;
  height: 64px;
}

.ldsDualRing:after {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border-color: var(--color-brightPurple) transparent var(--color-brightPurple) transparent;
  border-style: solid;
  border-width: 4px;
  animation: ldsDualRing 1.2s linear infinite;
}

@keyframes ldsDualRing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Colors */
.brightPurple:after {
  border-color: var(--color-brightPurple) transparent var(--color-brightPurple) transparent;
}

.navy:after {
  border-color: var(--color-navy) transparent var(--color-navy) transparent;
}

.primary:after {
  border-color: var(--color-primary) transparent var(--color-primary) transparent;
}

.white:after {
  border-color: var(--color-white) transparent var(--color-white) transparent;
}

/* Fonts */
.body1 {
  height: var(--line-height-body1);
  width: var(--line-height-body1);
}

.body2 {
  height: var(--line-height-body2);
  width: var(--line-height-body2);
}

.navLink {
  height: var(--line-height-navLink);
  width: var(--line-height-navLink);
}