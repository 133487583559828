.body {
  max-width: 750px;
}

.button {
  display: block;
  margin: auto;
  margin-top: 48px;
  width: 280px;
}

.description { 
  margin-top: 16px;
}

.inputs {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 48px;
  max-width: 640px;
  row-gap: 48px;
}

.usernameInput {
  padding-left: var(--padding-left-username);
}