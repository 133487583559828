.button {
  align-items: center;
  border-radius: 100px;
  border-style: none;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  padding: 16px;
  outline: none;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

.button:disabled,
.disabled {
  cursor: default;
  opacity: 48%;
  pointer-events: none;
}

.buttonAutoWidth {
  padding: 16px 32px;
}

.button:enabled:hover {
  cursor: pointer;
}

.icon {
  /* Fixes https://github.com/vercel/next.js/issues/21914 */
  font-size: 0px;
  line-height: 0px;
}

.iconLeft {
  margin-right: 10px;
}

.iconRight {
  margin-left: 10px;
}

.linkContent:hover {
  cursor: pointer;
}

.linkInternal {
  /* Otherwise, height gets messed up */
  display: flex;
}

/*
 * Themes
 */

/* Bright purple outline theme */

.brightPurpleOutlineTheme,
.brightPurpleOutlineThemeLink {
  background-color: var(--color-white);
  border: 2px solid var(--color-brightPurple);
  color: var(--color-brightPurple);
  /* Account for border */
  padding-bottom: 14px;
  padding-top: 14px;
}

.brightPurpleOutlineTheme:enabled:active,
.brightPurpleOutlineThemeLink:active {
  background-color: var(--color-brightPurple);
  color: var(--color-white);
}

.brightPurpleOutlineTheme:enabled:active path,
.brightPurpleOutlineThemeLink:active path {
  stroke: var(--color-white);
}

@media (hover: hover) {
  .brightPurpleOutlineTheme:enabled:hover,
  .brightPurpleOutlineThemeLink:hover {
    background-color: var(--color-brightPurple);
    color: var(--color-white);
  }

  .brightPurpleOutlineTheme:enabled:hover path,
  .brightPurpleOutlineThemeLink:hover path {
    stroke: var(--color-white);
  }
}

/* Light pink outline theme */

.lightPinkOutlineTheme,
.lightPinkOutlineThemeLink {
  background-color: transparent;
  border: 2px solid var(--color-lightPink);
  color: var(--color-lightPink);
}

.lightPinkOutlineTheme:enabled:active,
.lightPinkOutlineThemeLink:active {
  background-color: var(--color-lightPink);
  color: var(--color-white);
}

@media (hover: hover) {
  .lightPinkOutlineTheme:enabled:hover,
  .lightPinkOutlineThemeLink:hover {
    background-color: var(--color-lightPink);
    color: var(--color-white);
  }
}

/* Navy theme */

.navyTheme,
.navyThemeLink {
  background-color: var(--color-navy);
  color: var(--color-white);
}

.navyTheme:enabled:active,
.navyThemeLink:active {
  background-color: var(--color-navyHover);
}

@media (hover: hover) {
  .navyTheme:enabled:hover,
  .navyThemeLink:hover {
    background-color: var(--color-navyHover);
  }
}

/* Navy outline square outline theme */

.navyOutlineSquareTheme,
.navyOutlineSquareThemeLink {
  background-color: transparent;
  border: 2px solid var(--color-navy);
  border-radius: 4px;
  color: var(--color-navy);
  /* Account for border */
  padding-bottom: 14px;
  padding-top: 14px;
}

.navyOutlineSquareTheme:enabled:active,
.navyOutlineSquareThemeLink:active {
  background-color: var(--color-navy);
  color: var(--color-white);
}

.navyOutlineSquareTheme:enabled:active path,
.navyOutlineSquareThemeLink:active path {
  stroke: var(--color-white);
}

@media (hover: hover) {
  .navyOutlineSquareTheme:enabled:hover,
  .navyOutlineSquareThemeLink:hover {
    background-color: var(--color-navy);
    color: var(--color-white);
  }

  .navyOutlineSquareTheme:enabled:hover path,
  .navyOutlineSquareThemeLink:hover path {
    stroke: var(--color-white);
  }
}

/* Purple gradient theme */

.purpleGradientTheme,
.purpleGradientThemeLink {
  background: var(--background-purpleGradient);
  color: var(--color-white);
}

.purpleGradientTheme:enabled:active,
.purpleGradientThemeLink:active {
  background: linear-gradient(
      0deg,
      rgba(6, 1, 129, 0.64),
      rgba(6, 1, 129, 0.64)
    ),
    var(--background-purpleGradient);
  color: var(--color-white);
}

@media (hover: hover) {
  .purpleGradientTheme:enabled:hover,
  .purpleGradientThemeLink:hover {
    background: linear-gradient(
        0deg,
        rgba(6, 1, 129, 0.64),
        rgba(6, 1, 129, 0.64)
      ),
      var(--background-purpleGradient);
    color: var(--color-white);
  }
}

/* Purple outline theme */

.purpleOutlineTheme,
.purpleOutlineThemeLink {
  background-color: var(--color-white);
  border: 2px solid var(--color-purple);
  color: var(--color-purple);
  /* Account for border */
  padding-bottom: 14px;
  padding-top: 14px;
}

.purpleOutlineTheme:enabled:active,
.purpleOutlineThemeLink:active {
  background-color: var(--color-purple);
  color: var(--color-white);
}

.purpleOutlineTheme:enabled:active path,
.purpleOutlineThemeLink:active path {
  stroke: var(--color-white);
}

@media (hover: hover) {
  .purpleOutlineTheme:enabled:hover,
  .purpleOutlineThemeLink:hover {
    background-color: var(--color-purple);
    color: var(--color-white);
  }

  .purpleOutlineTheme:enabled:hover path,
  .purpleOutlineThemeLink:hover path {
    stroke: var(--color-white);
  }
}

/* Red outline theme */

.redOutlineTheme,
.redOutlineThemeLink {
  background-color: var(--color-white);
  border: 2px solid var(--color-red);
  color: var(--color-red);
  /* Account for border */
  padding-bottom: 14px;
  padding-top: 14px;
}

.redOutlineTheme:enabled:active,
.redOutlineThemeLink:active {
  background-color: var(--color-red);
  color: var(--color-white);
}

.redOutlineTheme:enabled:active path,
.redOutlineThemeLink:active path {
  stroke: var(--color-white);
}

@media (hover: hover) {
  .redOutlineTheme:enabled:hover,
  .redOutlineThemeLink:hover {
    background-color: var(--color-red);
    color: var(--color-white);
  }

  .redOutlineTheme:enabled:hover path,
  .redOutlineThemeLink:hover path {
    stroke: var(--color-white);
  }
}

/* Red theme */

.redTheme,
.redThemeLink {
  background-color: var(--color-red);
  color: var(--color-white);
}

.redTheme:enabled:active,
.redThemeLink:active {
  background-color: var(--color-redHover);
}

@media (hover: hover) {
  .redTheme:enabled:hover,
  .redThemeLink:hover {
    background-color: var(--color-redHover);
  }
}

/* Secondary outline theme */

.secondaryOutlineTheme,
.secondaryOutlineThemeLink {
  background-color: var(--color-white);
  border: 2px solid var(--color-secondary);
  color: var(--color-secondary);
  /* Account for border */
  padding-bottom: 14px;
  padding-top: 14px;
}

.secondaryOutlineTheme:enabled:active,
.secondaryOutlineThemeLink:active {
  background-color: var(--color-secondary);
  color: var(--color-white);
}

.secondaryOutlineTheme:enabled:active path,
.secondaryOutlineThemeLink:active path {
  stroke: var(--color-white);
}

@media (hover: hover) {
  .secondaryOutlineTheme:enabled:hover,
  .secondaryOutlineThemeLink:hover {
    background-color: var(--color-secondary);
    color: var(--color-white);
  }

  .secondaryOutlineTheme:enabled:hover path,
  .secondaryOutlineThemeLink:hover path {
    stroke: var(--color-white);
  }
}
