.tab {
  border-bottom: 3px solid transparent;
  column-gap: 16px;
  display: flex;
  padding-bottom: 13px;
  padding-left: 10px;
  padding-right: 10px;
}

.tabActive {
  border-bottom: 3px solid var(--color-brightPurple);
}
