:root {
  --color-backgroundOverlay: rgba(0, 0, 0, 0.3);
  --color-brightPurple: #8458ff;
  --color-dimmer: rgba(0, 0, 0, 0.36);
  --color-error: #ff7777;
  --color-green: #13bc68;
  --color-ghost: #c6c6c6;
  --color-lightBlue: #eaffff;
  --color-lightPink: #ffdcdc;
  --color-lightPurple: #D4D2FF;
  --color-lightPurpleHover: #d8d6ff;
  --color-navy: #1d1989;
  --color-navyHover: #080561;
  --color-pink: #ffc8c8;
  --color-primary: #201F2A;
  --color-purple: #3530bf;
  --color-red: #fd4646;
  --color-redHover: #dd1111;
  --color-secondary: #9796A5;
  --color-shader: #F5F4FE;
  --color-tertiary: #DEDDE5;
  --color-white: #ffffff;
  --color-yellow: #ffbb37;

  --color-phantomPurple: #6C57EB;

  --background-purpleGradient: linear-gradient(91.96deg, #4e48f2 17.52%, #8458ff 91.27%);
}
