.description {
  align-items: center;
  column-gap: 8px;
  display: flex;
}

.image {
  align-items: center;
  background-color: var(--color-lightPurple);
  border-radius: 50%;
  display: flex;
  height: 32px;
  justify-content: center;
  margin-left: 4px;
  width: 32px;
}

.left {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.price {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.profileLink {
  align-items: center;
  column-gap: 8px;
  display: flex;
}

.transaction {
  display: flex;
  justify-content: space-between;
  padding: 16px 0px;
}