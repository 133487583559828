.auction {
  background: var(--background-purpleGradient);
  column-gap: 36px;
  display: flex;
  margin-top: 20px;
  padding: 20px 24px 24px;
}

.withSeparator {
  border-top: 1px solid var(--color-tertiary);
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 20px;
  padding: 20px 0px 24px;
}